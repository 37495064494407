dmx.Component('preloader', {

  attributes: {
    preview: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: '#333',
    },

    bgcolor: {
      type: String,
      default: '#fff',
    },

    size: {
      type: Number,
      default: 60,
    },

    spinner: {
      type: String,
      default: '',
    },
  },

  methods: {
    show () {
      this._show();
    },

    hide () {
      this._hide();
    },
  },

  init (node) {
    this._checkLoaded = this._checkLoaded.bind(this);

    this._documentLoaded = false;
    this._imagesLoaded = false;
    this._dataLoaded = false;
    this._spinners = {
      rotatingPlane: 0,
      doubleBounce: 2,
      wave: 5,
      wanderingCubes: 2,
      pulse: 0,
      chasingDots: 2,
      threeBounce: 3,
      circle: 12,
      cubeGrid: 9,
      fadingCircle: 12,
      foldingCube: 4
    };

    document.addEventListener('readystatechange', this._checkLoaded);

    requestAnimationFrame(() => {
      this._checkLoaded();
    });
  },

  destroy () {
    document.removeEventListener('readystatechange', this._checkLoaded);
  },

  render (node) {
    const { color, bgcolor, size, spinner } = this.props;

    node.classList.add('dmxPreloader');
    
    node.style.setProperty('--color', color);
    node.style.setProperty('--bgcolor', bgcolor);
    node.style.setProperty('--size', size + 'px');

    if (spinner) {
      node.innerHTML = `
        <div class="dmxPreloader-spinner dmxPreloader-${spinner}">
          ${Array.from({ length: this._spinners[spinner] }).map(() => `
            <div></div>
          `).join('')}
        </div>
      `;
    }

    this._show();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('color')) {
      this.$node.style.setProperty('--color', this.props.color);
    }

    if (updatedProps.has('bgcolor')) {
      this.$node.style.setProperty('--bgcolor', this.props.bgcolor);
    }

    if (updatedProps.has('size')) {
      this.$node.style.setProperty('--size', this.props.size + 'px');
    }
  },

  _show () {
    this.$node.style.removeProperty('opacity');
    this.$node.style.removeProperty('z-index');
    document.body.style.setProperty('overflow', 'hidden');
  },

  _hide () {
    this.$node.style.setProperty('opacity', 0);
    this.$node.style.setProperty('z-index', -1);
    document.body.style.removeProperty('overflow');
  },

  _checkLoaded () {
    // When preview do nothing
    if (this.props.preview) return;

    this._checkDocumentLoaded();
    this._checkImagesLoaded();
    this._checkDataLoaded();

    if (this._documentLoaded && this._imagesLoaded && this._dataLoaded) {
      this._hide();
    }
  },

  _checkDocumentLoaded () {
    this._documentLoaded = (document.readyState === 'complete');
  },

  _checkImagesLoaded () {
    this._imagesLoaded = Array.from(document.getElementsByTagName('img')).every(img => {
      if (img.complete) return true;

      if (!img.isListening) {
        const image = new Image();
        image.addEventListener('load', () => this._checkLoaded());
        image.addEventListener('error', () => this._checkLoaded());
        image.src = img.src;
        img.isListening = true;
      }

      return false;
    });
  },

  _checkDataLoaded () {
    this._dataLoaded = this._dataComponents().every(component => {
      return component.props.noload || component.data != null || (component._xhr && component._xhr.readyState === 4);
    });
  },

  _dataComponents () {
    const components = [];

    dmx.app.children.forEach(function _getDataComponents(component) {
      if (component instanceof dmx.Component('fetch')) {
        components.push(component);
      }

      component.children.forEach(_getDataComponents);
    });

    return components;
  }

});
